@import "../../styles/variables";
@import "../../styles/mixins/utilities-rem";

.hero, .default {
  position: relative;
}

.closeButton {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  border: 0;
  cursor: pointer;
  padding: rem(10);
  position: absolute;
  top: rem(15);
  right: rem(15);

  svg {
    height: rem(24);
    width: rem(24);
    fill: $black;
  }
}

.hero {
  background-image: url("https://images.ctfassets.net/5eyh4hgawl2i/rRaHFJiE5kxOM1oVJIWGg/c3150bd89959f1eaa93f89d758eb4518/Karamelli_karuselli08.png?fm=webp&q=80&w=1980");
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  height: rem(200);

  h1 {
    background: $black;
    color: $white;
    margin: 0;
    text-align: center;
    padding: 1rem 2rem;
    text-transform: uppercase;
  }

  > .closeButton {
    background-color: rgba(255, 255, 255, 0.8);
  }
}

.default {
  display: flex;
  align-items: center;
  background: $white;
  padding: rem(25) rem(30);

  h1 {
    color: $black;
    font-size: rem(24);
    margin: 0;
    text-transform: uppercase;
    text-align: center;
  }

  > .closeButton {
    background-color: transparent;
  }
}

@media #{$breakpoint-not-small} {
  .hero {
    height: rem(250);
  }
}
