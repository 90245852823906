.top-50 { top: 50%; }
.top-100 { top: 100%; }

.bottom-50 { bottom: 50%; }
.bottom-100 { bottom: 100%; }

.left-50 { left: 50%; }
.left-100 { left: 100%; }

.right-50 { right: 50%; }
.right-100 { right: 100%; }

@media #{$breakpoint-not-small} {
  .top-50-ns { top: 50%; }
  .top-100-ns { top: 100%; }

  .bottom-50-ns { bottom: 50%; }
  .bottom-100-ns { bottom: 100%; }

  .left-50-ns { left: 50%; }
  .left-100-ns { left: 100%; }

  .right-50-ns { right: 50%; }
  .right-100-ns { right: 100%; }
}

@media #{$breakpoint-medium} {
  .top-50-m { top: 50%; }
  .top-100-m { top: 100%; }

  .bottom-50-m { bottom: 50%; }
  .bottom-100-m { bottom: 100%; }

  .left-50-m { left: 50%; }
  .left-100-m { left: 100%; }

  .right-50-m { right: 50%; }
  .right-100-m { right: 100%; }
}

@media #{$breakpoint-large} {
  .top-50-l { top: 50%; }
  .top-100-l { top: 100%; }

  .bottom-50-l { bottom: 50%; }
  .bottom-100-l { bottom: 100%; }

  .left-50-l { left: 50%; }
  .left-100-l { left: 100%; }

  .right-50-l { right: 50%; }
  .right-100-l { right: 100%; }
}
