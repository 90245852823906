// React Calendar
//
// When using React Calendar for displaying dynamic pricing data, we
// need to add extra class to adjust style changes for the calendar.
//

.react-calendar__tile {
  abbr {
    border: 2px dotted transparent;
  }

  &.react-calendar__tile--now {
    abbr {
      border-color: #ccc;
    }
  }
}

.has-pricing-data {
  .react-calendar__tile {
    &.hover:not(.closed):not(:disabled):hover {
      cursor: pointer;
  
      abbr {
        background-color: black !important;
        color: white;
      }
    }
  
    &.react-calendar__tile--active {
      &:not(.closed):not(:disabled) {
        abbr {
          background-color: black !important;
          color: white;
        }
      }
    }
  
    &.cheaper-price {
      &:not(.closed):not(:disabled):not(.react-calendar__month-view__days__day--neighboringMonth) {
        abbr {
          background-color: $turquoise;
        }
      }
    }

    &.special-event:not(.react-calendar__month-view__days__day--neighboringMonth) {
      abbr {
        position: relative;

        &::before {
          content: '';
          position: absolute;
          bottom: 11%;
          right: 43%;
          width: 15%;
          height: 15%;
          border-radius: 50%;
          background-color: $pink;
        }
      }
    }
  }
}
