.ofi-contain { object-fit: contain; }
.ofi-cover { object-fit: cover; }

.ofi--bottom { object-position: bottom; }
.ofi--center { object-position: center; }
.ofi--left { object-position: left; }
.ofi--left-bottom { object-position: left bottom; }
.ofi--left-top { object-position: left top; }
.ofi--right { object-position: right; }
.ofi--right-bottom { object-position: right bottom; }
.ofi--right-top { object-position: right top; }
.ofi--top { object-position: top; }
