/**
 * @license
 * MyFonts Webfont Build ID 3954736, 2020-10-01T04:38:55-0400
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: BebasNeue-Regular by Dharma Type
 * URL: https://www.myfonts.com/fonts/flat-it/bebas-neue/regular/
 * Copyright: Copyright &amp;#x00A9; 2010 by Dharma Type.
 * 
 * 
 * 
 * © 2020 MyFonts Inc
*/

@font-face {
  font-family: 'BebasNeue-Regular';
  font-weight: 400;
  src: url('BebasNeue-Regular/font.woff2') format('woff2'), url('BebasNeue-Regular/font.woff') format('woff');
}
