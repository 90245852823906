@import "./mixins/utilities-em";
@import "./mixins/utilities-rem";
@import "variables";
@import "tachyons";
@import "global";
@import "tachyons-additions";
@import "components/react-calendar";
@import "components/react-calendar-dynamic-pricing-additions";
@import "components/photoswipe";
@import "components/swiper";
@import "components/react-toastify-additions";
@import "components/react-modal-additions";
@import "components/masonry-grid";
@import "components/loading-spinner";

@media (min-width: em(1400)) {
  html {
    font-size: calc(1rem + ((1vw - 0.88rem) * 1.05));
  }
}

html,
body,
#__next {
  height: 100%;
}

body {
  padding-top: rem(74);
}

.button-text-vertical-adjustment {
  padding-top: rem(17);
}

.minicart-toggle:hover .minicart-item-count {
  background-color: $green;
  color: $white;
}

.minicart-item-count {
  width: rem(16);
  height: rem(16);
  font-size: rem(12);

  @media #{$breakpoint-large} {
    width: rem(22);
    height: rem(22);
    font-size: rem(15);
  }
}

.icon-wrapper-small {
  width: rem(42);
  height: rem(42);
}

.icon-wrapper-large {
  width: rem(48);
  height: rem(48);
}

@media #{$breakpoint-not-small} {
  .icon-wrapper-small-ns {
    width: rem(42);
    height: rem(42);
  }

  .icon-wrapper-large-ns {
    width: rem(48);
    height: rem(48);
  }
}

@media #{$breakpoint-medium} {
  .icon-wrapper-small-m {
    width: rem(42);
    height: rem(42);
  }

  .icon-wrapper-large-m {
    width: rem(48);
    height: rem(48);
  }
}

@media #{$breakpoint-large} {
  .icon-wrapper-small-l {
    width: rem(42);
    height: rem(42);
  }

  .icon-wrapper-large-l {
    width: rem(48);
    height: rem(48);
  }
}

@media #{$breakpoint-small-only} {
  td.responsive-td-header:before {
    width: 8rem;
    white-space: nowrap;
    font-weight: 600;
    content: attr(data-th);
  }
}

@media #{$breakpoint-not-small} {
  tr.highlight-even-tr:nth-child(even) > td {
    background: #f0efef;
  }
}

@media #{$breakpoint-large} {
  .no-pointer-events-l {
    pointer-events: none;
  }
}
