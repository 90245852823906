// Swiper
//
// Additional global modifications to make Swiper fit to site visuals.
//

.swiper-pagination-bullet.swiper-pagination-bullet {
  width: rem(14);
  height: rem(14);
  border: rem(2) solid $black;
  background: transparent;
  opacity: 1;
  margin: 0 rem(5);
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: $black;
}
