@import "../../styles/variables";
@import "../../styles/mixins/utilities-rem";

.modal {
  position: relative;
  left: 47.5%;
  right: auto;
  transform: translate(-47.5%, 0);
  width: 95%;
  background: transparent;
  overflow: visible;
  margin-top: 1rem;
  margin-bottom: 2.5rem;
  padding: 0;

  h3 {
    text-transform: uppercase;
    text-align: left;
    width: 100%;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.50);
}

.linkButton {
  color: $black;
  border: 0;
  padding: 0;
  margin: 0;
  text-decoration: underline;
  cursor: pointer;
  background: transparent;
}

@media #{$breakpoint-not-small} {
  .modal {
    width: 25rem;
  }
}
