@import "../styles/mixins/utilities-rem";
@import "../styles/variables";

.topDecoration {
  background-color: white;
  background-image: url("../../public/svg/background-carousell.svg");
  background-position: top center;
  background-repeat: repeat-x;
  background-size: rem(64);
  height: rem(64);
}

.subscriptionForm {
  position: relative;
  max-width: rem(480);
}

.subscriptionFormDecoration {
  position: absolute;
  top: 0;
  bottom: 0;
  background-image: url("../../public/svg/background-cotton-candy.svg");
  background-position: top center;
  background-repeat: repeat-y;
  background-size: contain;

  @media #{$breakpoint-small-only} {
    display: none;
  }

  &--left {
    left: 0;
    transform: translateX(calc(#{rem(1)} - 100%));
  }

  &--right {
    right: 0;
    transform: translateX(calc(100% - #{rem(1)} ));
  }
}

.subscriptionFormDecorationSizer {
  width: auto;
  height: 100%;
}

.smallContactInfo {
  background-color: $vihrea;
  color: $white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 600;
  padding: 2rem;
}

.copyright {
  background-color: $luonnonvalkoinen;
  text-align: center;
  padding: 0.5rem 0;
}
