@import "../../styles/variables";
@import "../../styles/mixins/utilities-rem";

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: rem(30);
  background: $white;

  > button {
    margin: 0 0 rem(15) 0;
    width: 100%;

    ~ h3 {
      margin: 1rem 0;
    }

    &:last-child {
      margin: 0;
    }
  }

  h3 {
    text-align: left;
    text-transform: uppercase;
    width: 100%;
    margin: 0 0 1rem 0;

    ~ p {
      margin: 0 0 2rem 0;
    }
  }

  > p {
    margin: 1rem 0 2rem 0;
  }
}
