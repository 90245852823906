.childrenMenuList {
  &:after {
    position: absolute;
    content: "";
    top: 0;
    left: -100%;
    width: 1000%;
    height: 100%;
    background-color: #fff;
  }
}
