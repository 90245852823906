@import "../styles/variables";

.label {
  $form-control-color: $pink;
  $form-control-disabled: #959495;

  display: flex;
  flex-direction: row;
  align-items: center;

  > span {
    margin-left: rem(8);
  }

  input[type="checkbox"] {
      /* Remove most all native input styles */
      appearance: none;
      /* For iOS < 15 */
      background-color: white;
      /* Not removed via appearance */
      margin: 0;
    
      font: inherit;
      color: currentColor;
      width: 1.15em;
      height: 1.15em;
      border: 0.15em solid currentColor;
      border-radius: 0.15em;
      transform: translateY(-0.075em);
    
      display: grid;
      place-content: center;
    
    &::before {
      content: "";
      width: 0.65em;
      height: 0.65em;
      clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
      transform: scale(0);
      transform-origin: bottom left;
      transition: 120ms transform ease-in-out;
      box-shadow: inset 1em 1em $form-control-color;
      /* Windows High Contrast Mode */
      background-color: CanvasText;
    }
    
    &:checked::before {
      transform: scale(1);
    }
    
    &:focus {
      border-color: $form-control-color;
    }
    
    &:disabled {
      color: $form-control-disabled;
      cursor: not-allowed;
    
      &::before {
        box-shadow: inset 1em 1em $form-control-disabled;
      }
    }
  }
}

