// PhotoSwipe
//
// Additional global styles to improve PhotoSwipe usability.
//

// Displays images correctly when width and height are unknown
.pswp img {
  max-width: none;
  object-fit: contain;
}

// Hide janky animation when image width and height are unknown
.pswp__img--placeholder--blank {
  display: none;
}

// Override default caption styles
.pswp__caption .pswp__caption__center {
  color: #ccc;
  margin: 0 auto;
  max-width: 420px;
  padding: 10px;
  text-align: center;

  @extend .fw5;
  @extend .f6-l;
  @extend .f7;
  @extend .lh-copy;
}
