.w-45 {
  width: 45%;
}

@media #{$breakpoint-not-small} {
  .w-45-ns {
    width: 45%;
  }
}

@media #{$breakpoint-medium} {
  .w-45-m {
    width: 45%;
  }
}

@media #{$breakpoint-large} {
  .w-45-l {
    width: 45%;
  }
}
