img {
  height: auto;
  display: block;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  color: inherit;
}

button:focus,
a:focus,
input[type="checkbox"]:focus + span::before {
  outline: none;
}

[data-whatinput="keyboard"] button:focus,
[data-whatinput="keyboard"] a:focus,
[data-whatinput="keyboard"] input[type="checkbox"]:focus + span::before {
  outline: blue solid 5px;
}

svg {
  fill: currentColor;
}

picture {
  display: block;
}

strong {
  font-weight: 600;
}

a {
  color: black;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}
