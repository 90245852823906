.momentum-scrolling {
  -webkit-overflow-scrolling: touch;
}

.pointer-events-none { pointer-events: none; }
.pointer-events-auto { pointer-events: auto; }

.flip { transform: scaleX(-1); }

@media #{$breakpoint-not-small} {
  .pointer-events-none-ns { pointer-events: none; }
  .pointer-events-auto-ns { pointer-events: auto; }
}

@media #{$breakpoint-medium} {
  .pointer-events-none-m { pointer-events: none; }
  .pointer-events-auto-m { pointer-events: auto; }
}

@media #{$breakpoint-large} {
  .pointer-events-none-l { pointer-events: none; }
  .pointer-events-auto-l { pointer-events: auto; }
}
