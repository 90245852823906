@import "../../styles/mixins/utilities-rem";
@import "../../styles/variables";

.button {
  display: flex;
  flex-direction: row;
  padding: rem(15);
  align-items: center;
  justify-content: center;
  border: rem(1.5) solid $black;
  border-radius: rem(38);
  font-size: 1rem;
  cursor: pointer;
  position: relative;

  svg {
    margin-right: rem(10);
    fill: currentColor;
    height: rem(24);
    width: rem(24);

    &.loading {
      @extend .spin-reverse;
      right: 1rem;
      position: absolute;
    }

    &.checkmark {
      fill: $light-turquoise;
    }
  }

  &.primary {
    background: $black;
    color: $white;
  }

  &.secondary {
    background: $white;
    color: $black;
  }

  &.bold {
    font-weight: 600;
  }

  &[disabled] {
    cursor: not-allowed;
    
    &.primary {
      background: $light-gray;
      color: $dark-gray;
      border-color: $light-gray;
    }
  }
}

.spin-reverse {
  animation-name: spin-reverse;
  animation-duration: 800ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear; 
}

@keyframes spin-reverse {
  from {
      transform:rotate(0deg);
  }
  to {
      transform:rotate(-360deg);
  }
}
