@import "../../styles/variables";
@import "../../styles/mixins/utilities-rem";

.field {
  display: block;
  font-weight: 400;
  margin-bottom: 1rem;
  width: 100%;

  .inputContainer {
    position: relative;

    > input {
      padding: rem(15) rem(40) rem(15) rem(20);
    }

    .iconButton {
      background: transparent;
      border: 0;
      cursor: pointer;
      padding: 0;
      position: absolute;
      right: 0.8rem;
      top: 0.8rem;

      svg {
        display: block;
        width: 1.5rem;
        height: 1.5rem;
        fill: #999;
        transition: .1s fill;
      }

      &:hover {
        svg {
          fill: black;
        }
      }
    }
  }

  > input, .inputContainer > input  {
    font-weight: 600;
    display: block;
    border-radius: rem(15);
    border: rem(1.5) solid $black;
    width: 100%;
  }

  > input {
    padding: rem(15) rem(20);
  }

  .errorList {
    color: $pink;
    font-size: 1rem;
    font-weight: 600;
  }
}
