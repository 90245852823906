// React Calendar
//
// React Calendar has very sparse styling so we need
// to add global styles to make it match site visuals.
//

.react-calendar {
  text-align: center;
}

.react-calendar__navigation__label {
  text-transform: capitalize;

  @extend .f3;
  @extend .b;
}

.react-calendar__navigation__label {
  background-color: transparent;
  border: none;

  @extend .pb3;
  @extend .pt3;
  padding-left: 0;
  padding-right: 0;
}

.react-calendar__navigation__arrow {
  background-color: transparent;
  border: none;
  padding: 0;
}

.react-calendar__navigation__arrow:disabled {
  color: $moon-gray;

  > span {
    color: inherit;
    cursor: auto;
  }
}

.react-calendar__month-view__weekdays {
  @extend .pt3;
  @extend .pb3;
}

.react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none;
  text-transform: capitalize;

  @extend .f6;
  @extend .f5-l;
  @extend .fw6;
}

.react-calendar__month-view__days {
  align-items: flex-start;
}

.react-calendar__tile {
  background-color: transparent;
  border: none;

  @extend .pv2;
  @extend .pv3-ns;
  padding-left: 0;
  padding-right: 0;

  abbr {
    display: block;
    border-radius: 50%;
    background-color: transparent;
    color: $moon-gray;
    width: rem(35);
    height: rem(35);
    line-height: rem(35);
    margin-left: auto;
    margin-right: auto;

    @extend .f6;
    @extend .f5-l;
    @extend .fw6;

    @media #{$breakpoint-large} {
      width: rem(48);
      height: rem(48);
      line-height: rem(48);
    }
  }

  &.not-available, &.closed {
    abbr {
      color: $dark-gray;
      background-color: $light-gray;
    }
  }

  &.available, &.open {
    abbr {
      background-color: $bright-turquoise;
      color: $black;
    }
  }

  &:not([disabled]) {
    &:hover {
      cursor: pointer;
    }

    &:not(.react-calendar__month-view__days__day--neighboringMonth) {
      abbr {
        color: $black;
      }
    }
  }

  &.hover {
    &:not(:disabled) {
      &:hover {
        abbr {
          background-color: #000 !important;
          color: #fff !important;
        }
      }
    }
  }

  &.react-calendar__month-view__days__day--neighboringMonth {
    abbr {
      background-color: transparent;
      color: $moon-gray;
    }
  
    span {
      display: none;
    }
  }
}

.react-calendar__tile--active {
  &:not(:disabled) {
    abbr {
      background-color: #000 !important;
      color: #fff !important;
    }
  }
}

.contentModuleOpeningHours .react-calendar__tile {
  @media #{$breakpoint-small-only} {
    padding: 0 0 0.25rem 0;
  }
}
