label.toggle-switch {
  input[type="checkbox"] {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }

  input[type="checkbox"] + span {
    width: 44px;
    height: 22px;
    background-color: $moon-gray;
  }

  input[type="checkbox"]:checked + span {
    border: 2px solid $pink;
    box-sizing: border-box;
    background-color: white;
  }

  input[type="checkbox"] + span:after {
    content: "";
    display: block;
    position: relative;
    height: 22px;
    width: 22px;
    border-radius: 50%;
    box-sizing: border-box;
    border: 1px solid $moon-gray;
    background-color: white;
    transition: left 100ms;
    left: 0px;
  }

  input[type="checkbox"]:checked + span:after {
    border: 2px solid $green;
    background-color: $green;
    top: -2px;
    left: 20px;
  }
}

label.checkbox-wrapper {
  input[type="checkbox"] + span::before {
    cursor: pointer;
  }
  
  input[type="checkbox"] {
    opacity: 0;
    margin-left: -13px;
  }
  
  input[type="checkbox"] + span::before {
    border: 1px solid $green;
    border-radius: 2px;
    content: "\00a0";
    display: inline-block;
    font-family: inherit;
    font: 22px/1em sans-serif;
    margin: 0 .25em 0 0;
    padding: 0;
    vertical-align: top;
    width: 22px;
    height: 22px;
  }
  
  input[type="checkbox"]:checked + span::before {
    content: "\2713";
    background: $green;
    color: #ffffff;
    text-align: center;
  }
  
  input[type="checkbox"]:checked + span::after {
    font-weight: bold;
  }
}
