// React Toastify
//
// Overwrites for default styles.
//

.Toastify__toast {
  font-family: $sans-serif;
  line-height: $line-height-copy;

  @media all and (min-width: 481px) {
    padding: rem(8);
    width: rem(320);
  }
}

.Toastify__toast-container {

  @media all and (min-width: 481px) {
    padding: rem(4);
    width: rem(320);
  }
}

.Toastify__toast--error {
  background-color: $pink;
}

.Toastify__toast--info {
  background-color: $turquoise;
}
