$top_start: 15%;
$top_end: 0;

.ReactModal__Overlay {
  z-index: 999999;
  opacity: 0;
  transition: opacity .20s ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.ReactModal__Content {
  font-family: $sans-serif;
  line-height: $line-height-copy;
  top: $top_start;
  transition-property: top;
  transition-duration: .20s;
  transition-timing-function: ease-in-out;

  &:focus {
    outline: 0;
  }
}

.ReactModal__Content--after-open {
  top: $top_end;
}

.ReactModal__Content--before-close {
  top: $top_start;
}

.confirm-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.50);
}

.confirm-content, .policy-content, .modal-content {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.confirm-content, .policy-content {
  position: fixed;
}

.modal-content {
  position: absolute;
}

@media #{$breakpoint-not-small} {
  .confirm-content {
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    transform: translate(-50%, -50%);
    min-height: 10rem;
    min-width: 20rem;
    width: 80%;
    max-width: 40rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .policy-content {
    left: 50%;
    right: auto;
    transform: translate(-50%, 0);
    min-height: 10rem;
    min-width: 25rem;
    width: 80%;
    max-width: 50rem;
  }

  .modal-content {
    position: relative;
    left: 50%;
    right: auto;
    transform: translate(-50%, 0);
    width: 100%;
    max-width: 55rem;
    border: 0.5rem solid $green;
    overflow: visible;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}
