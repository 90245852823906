/**
 * @license
 * MyFonts Webfont Build ID 3879348, 2020-03-12T04:10:26-0400
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: Goldplay-Regular by Latinotype
 * URL: https://www.myfonts.com/fonts/latinotype/goldplay/regular/
 *
 * Webfont: Goldplay-SemiBold by Latinotype
 * URL: https://www.myfonts.com/fonts/latinotype/goldplay/semi-bold/
 *
 * Webfont: Goldplay-Bold by Latinotype
 * URL: https://www.myfonts.com/fonts/latinotype/goldplay/bold/
 *
 * Webfonts copyright: Copyright © 2019 by Enrique Hernandez Vasquez. All rights reserved.
 *
 * © 2020 MyFonts Inc
*/


/* @import must be at top of file, otherwise CSS will not work */
@font-face {
  font-family: "Trade Gothic Next LT Pro";
  font-stretch: normal;
  font-weight: 400;
  src: url("TradeGothicNextLTPro/TradeGothicNextLTPro-Rg.otf") format("opentype");
}

@font-face {
  src: url("TradeGothicNextLTPro/TradeGothicNextLTPro-Bd.otf") format("opentype");
  font-family: "Trade Gothic Next LT Pro";
  font-stretch: normal;
  font-weight: 700;
}

@font-face {
  font-family: "Trade Gothic Next LT Pro";
  font-stretch: normal;
  font-weight: 800;
  src: url("TradeGothicNextLTPro/TradeGothicNextLTPro-Hv.otf") format("opentype");
}

@font-face {
  font-family: "Trade Gothic Next LT Pro";
  font-stretch: condensed;
  font-weight: 400;
  src: url("TradeGothicNextLTPro/TradeGothicNextLTPro-Cn.otf") format("opentype");
}

@font-face {
  font-family: "Trade Gothic Next LT Pro";
  font-stretch: condensed;
  font-weight: 800;
  src: url("TradeGothicNextLTPro/TradeGothicNextLTPro-HvCn.otf") format("opentype");
}
