@import "../styles/variables";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  font-size: 1rem;
  padding: 1rem 0;
  text-align: center;

  h3 {
    margin: 1rem;
    text-transform: uppercase;
  }

  p {
    margin: 0;

    &.disclaimer {
      margin: 0 0 1rem 0;
    }
  }

  a {
    text-decoration: none;
    border-width: 0 0 1px 0;
    border-style: solid;
  }

  .inputContainer {
    align-items: flex-start;
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    gap: rem(16);
    height: auto;
    justify-content: center;
    margin: 0 0 1rem 0;

    @media #{$breakpoint-small-only} {
      flex-direction: column;
      align-items: center;
    }

    input[type="email"] {
      color: $black;
      margin: 0;
      padding: 1rem;
      border-width: 1px;
      border-style: solid;
      border-radius: 0;
      width: 15rem;

      &:focus {
        outline: 0;
      }
    }

    button[disabled] {
      background: $dark-gray;
      cursor: not-allowed;
    }
  }

  .subscriptions {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .subscription {
      margin-bottom: 1rem;

      svg {
        width: 1rem;
        height: 1rem;
        margin-right: 0.5rem;
        flex-shrink: 0;
      }

      label {
        display: flex;
        align-items: center;
        cursor: pointer;
        user-select: none;

        &:first-child {
          margin-right: 1rem;
        }
      }

      input[type="checkbox"] {
        clip: rect(0 0 0 0);
        clip-path: inset(50%);
        height: 1px;
        overflow: hidden;
        position: absolute;
        white-space: nowrap;
        width: 1px;
      }
    }

  }

  &.light {
    color: $white;

    a {
      color: $white;
      border-color: $white;
    }

    input[type="email"] {
      border-color: transparent;
    }
  }

  &.dark {
    color: $black;

    a {
      color: $black;
      border-color: $black;
    }

    input[type="email"] {
      border-color: $moon-gray;
    }
  }

}
