.nested-links a {
  color: $black;
  text-decoration: underline;

  &.Button {
    color: $white;
    text-decoration: none;
    transition: none;

    &.nn {
      color: $black;

      &:hover {
        color: $white;
      }
    }

    &:hover {
      color: $white;
    }
  }
}

.nested-links a:hover,
[data-whatinput="keyboard"] .nested-links a:focus {
  color: $black;
  text-decoration: none;
}

.nested-links a:focus {
  color: inherit;
}

.nested-contained-copy-separator {
  > * {
    margin-top: 0;
    margin-bottom: 0;
  }

  > * + * {
    margin-top: $spacing-copy-separator;
  }
}

.nested-heading-font-sizes {
  h2 {
    font-size: $font-size-2;
  }

  h3 {
    font-size: $font-size-3;
  }

  h4 {
    font-size: $font-size-5;
  }
}

.nested-list {
  ol li + li,
  ul li + li,
  ol > li,
  ul > li {
    margin-top: $spacing-copy-separator/2;
  }
}

.nested-contentful-rich-text-list {
  ol li p,
  ul li p {
    margin: 0;
  }
}
