.fill-current-color,
.fill-current-color > svg { fill: currentColor; }
.fill-transparent,
.fill-transparent > svg { fill: transparent; }
.stroke-current-color,
.stroke-current-color > svg { stroke: currentColor; }

.stroke-white,
.stroke-white > svg { stroke: white; }

.svg-icon-small svg {
  flex-shrink: 0;
  width: rem(15);
  height: auto;  
}

.svg-icon svg {
  flex-shrink: 0;
  width: rem(18);
  height: auto;
}

.svg-icon-large svg {
  flex-shrink: 0;
  width: rem(30);
  height: auto;
}
