@import "./mixins/utilities-em";
@import "./mixins/utilities-rem";

// Overwrite default Tachyons variables
// List of available variables can be found at
// node_modules/tachyons-sass/scss/_variables.scss
$sans-serif: "Trade Gothic Next LT Pro", sans-serif;
$line-height-solid: 1;
$line-height-title: 1.15;
$line-height-copy: 1.3125;
$max-width-7: rem(700);
$max-width-8: rem(1200);
$max-width-9: rem(1440);
$box-shadow-1: 0px 2px 6px rgba(0, 0, 0, 0.3);

$font-size-headline: rem(65);
$font-size-subheadline: rem(42);
$font-size-1: rem(30);
$font-size-2: rem(28);
$font-size-3: rem(25);
$font-size-4: rem(20);
$font-size-5: rem(18);
$font-size-6: rem(16);
$font-size-7: rem(14);
$font-size-8: rem(12.4);

$letter-spacing-1: 0.02em;

// What follows is a bunch of hacks to make things look ~nice. The problem is that the old colors need to be mapped
// to the new colors somehow (colors set in contentful must still work) and the simplest way to achieve that is to just
// make some arbitrary mappings. Also Tachyons causes some trouble as we also have to map to those colors. Soo... things
// are not that nice here. I suggest you nuke all of this ASAP.

// New brand colors
$musta: #000000;
$valkoinen: #ffffff;
$luonnonvalkoinen: #FFFAF0;
$punainen: #E63A22;
$vaaleanpunainen: #FFD6DA;
$keltainen: #FFEE71;
$vihrea: #00A85A;
$vaaleansininen: #B9F2FF;

// Old tachyons colors
$purple: #6800b1;
$light-purple: #e2b5f0;
$pink: #e6007e;
$light-pink: $vaaleanpunainen; // Quick hack (looks more like $lightest pink)
$green: $vihrea;
$turquoise: #1b938d;
$light-turquoise: $vaaleansininen; // Quick hack ($light turquoise doesn't have a matching color in new palette)
$lightest-blue: $vaaleansininen;
$bright-turquoise: #bdffef;
$orange: #ff8e00;
$light-orange: #ffe1bb;
$yellow: $luonnonvalkoinen; // Quick hack ($yellow should be $keltainen)
$white: $valkoinen;
$black: $musta;
$moon-gray: #ccc;
$dark-gray: #989898;
$light-gray: #eee;
$near-white: #f4f4f4;
$custom-skins: (
  "light-pink": $light-pink,
  "turquoise": $turquoise,
  "light-turquoise": $light-turquoise,
  "bright-turquoise": $bright-turquoise,
  "light-gray": $light-gray,
);

$breakpoint-small-only: "screen and (max-width: #{em(768)})";
$breakpoint-not-small: "screen and (min-width: #{em(768)})";
$breakpoint-medium: "screen and (min-width: #{em(768)}) and (max-width: #{em(1200)})";
$breakpoint-medium-plus: "screen and (min-width: #{em(1200)}) and (max-width: #{em(1399)})";
$breakpoint-large: "screen and (min-width: #{em(1200)})";
