.order-first { order: -99999; }
.order-initial { order: initial; }

@media #{$breakpoint-not-small} {
  .order-first-ns { order: -99999; }
  .order-initial-ns { order: initial; }
}

@media #{$breakpoint-medium} {
  .order-first-m { order: -99999; }
  .order-initial-m { order: initial; }
}

@media #{$breakpoint-large} {
  .order-first-l { order: -99999; }
  .order-initial-l { order: initial; }
}

.df { display: flex; }

@media #{$breakpoint-not-small} {
  .df-ns { display: flex; }
}

@media #{$breakpoint-medium} {
  .df-m { display: flex; }
}

@media #{$breakpoint-large} {
  .df-l { display: flex; }
}
