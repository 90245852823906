@import "../../styles/variables";

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;

  strong {
    display: block;
  }

  p {
    margin: 0 0 1rem 0;
  }

  .sub {
    color: $punainen;
  }
  
  .deleteButton {
    margin-bottom: 1rem;
    
    &:not([disabled]) {
      background: $punainen;
      color: white;
      border-color: $punainen;
    }
  }
}
