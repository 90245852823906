.f0 {
  font-size: 0;
}

.f8 {
  font-size: $font-size-8;
}

@media #{$breakpoint-not-small} {
  .f8 {
    font-size: $font-size-6;
  }
}

.f-detail {
  font-size: rem(12);
}
