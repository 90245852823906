.tracked-5 {
  letter-spacing: 0.05em;
}

.tracked-10 {
  letter-spacing: 0.1em;
}

.tracked-20 {
  letter-spacing: 0.2em;
}
